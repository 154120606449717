<template>
  <div class="page">
    <b-overlay
      :show="loadingDatasetsPage"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    />
    <div
      class="back-link"
      @click="$router.push({ name: 'Data' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('datasets.data') }}
      </span>
    </div>
    <div>
      <div class="top">
        <b-row>
          <b-col class="left-col">
            <span>
              <img
                src="@/assets/icons/files_ressources.svg"
                alt="Icon files resources"
              >
              {{ datasetsCount }} {{ $tc('words.dataset', datasetsCount) }}
            </span>
            <div
              class="add-btn"
              @click="$router.push({ name: 'AddDataset' })"
            >
              <b-icon-plus />
              {{ $t('datasets.add') }}
            </div>
          </b-col>
          <b-col class="right-col vertical">
            <button
              class="export-button"
              style="margin-top: 0.5rem;"
              @click="exportDatasetToODL"
            >
              Exporter au format ODL
              <i class="bi bi-file-earmark-arrow-down" />
            </button>
            <TableFilters
              :filter-name="'publish'"
              :choices="publishStatuses"
              @filter="setTableFilters"
            />
          </b-col>
        </b-row>
      </div>
      <SearchablePaginatedTable
        :name="'datasetsListTable'"
        :loading="loadingTable"
        :searchable="true"
        :search-placeholder="$t('datasets.search')"
        :search-function="SEARCH_DATASETS_LIST"
        :route-to="'DatasetDetail'"
        :fields="fields"
        :rows="rows"
        :count="datasetsCount"
        :items-per-page="datasetsPerPage"
        :sort-function="GET_DATASETS_LIST"
        :selectable="false"
        :allow-duplication="true"
        @sorted="setTableSorted"
        @change-page="changeTablePage"
        @access="goToDatasetPermissions"
        @duplicate="duplicateDataset"
        @delete="openDeleteModal"
      />
    </div>

    <DeleteDatasetWithResources
      :open="isDeleteDatasetWithResourceOpen"
      :dataset="datasetWithResourceModalData"
      @delete-dataset-only="deleteDataset"
      @delete-dataset-and-resources="deleteDatasetWithResources"
      @close="isDeleteDatasetWithResourceOpen = false"
    />
  </div>
</template>

<script>
import DeleteDatasetWithResources from '@/components/Modals/DeleteDatasetWithResources.vue';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import TableFilters from '@/components/TableFilters.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Datasets',

  components: {
    DeleteDatasetWithResources,
    SearchablePaginatedTable,
    TableFilters
  },

  data() {
    return {
      initiating: true,
      loadingDatasetsPage: false,
      loadingTable: false,
      tableSorted: {
        direction: null,
        field: null
      },
      datasetToDelete: null,
      isDeleteDatasetWithResourceOpen: false,
      datasetWithResourceModalData: {
        name: null,
        nbResources: null
      }
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
      'datasetsList',
      'datasetsCount',
      'isDatasetsListSearched'
    ]),
    ...mapState('table', [
      'currentTables'
    ]),

    publishStatuses() {
      return [
        {
          choice: 0,
          value: 'true',
          label: this.$t('words.published')
        },
        {
          choice: 1,
          value: 'false',
          label: this.$t('words.hidden')
        },
        {
          choice: 2,
          value: null,
          label: this.$t('words.all')
        }
      ];
    },

    fields() {
      return [
        {
          key: this.$t('datasets.table.fields.name'),
          apiName: 'display_name',
          sortable: true,
          width: '15%',
          centered: false
        },
        {
          key: this.$t('datasets.table.fields.organisation'),
          apiName: 'usergroup',
          sortable: true,
          width: '11%',
          centered: false
        },
        {
          key: this.$t('datasets.table.fields.creationDate'),
          apiName: 'creation_date',
          sortable: true,
          width: '12%',
          centered: true
        },
        {
          key: this.$t('datasets.table.fields.reviewDate'),
          apiName: 'last_revision_date',
          sortable: true,
          width: '11%',
          centered: true
        },
        {
          key: this.$t('datasets.table.fields.mainResource'),
          sortable: false,
          width: '12%',
          centered: false
        },
        {
          key: this.$t('datasets.table.fields.annexCount'),
          sortable: false,
          width: '8%',
          centered: true
        },
        {
          key: this.$t('datasets.table.fields.publish'),
          apiName: 'publish',
          sortable: true,
          width: '9%',
          centered: true
        },
        {
          key: this.$t('datasets.table.fields.access'),
          sortable: false,
          width: '5%',
          centered: true
        },
        {
          key: this.$t('datasets.table.fields.duplicate'),
          sortable: false,
          width: '6%',
          centered: true
        },
        {
          key: this.$t('datasets.table.fields.delete'),
          sortable: false,
          width: '7%',
          centered: true
        }
      ];
    },

    rows() {
      return this.datasetsList.map(el => {
        return {
          id: el.id,
          [this.$t('datasets.table.fields.name')]: el.display_name,
          [this.$t('datasets.table.fields.organisation')]: el.usergroup.display_name,
          [this.$t('datasets.table.fields.creationDate')]: new Date(el.creation_date).toLocaleString('fr-FR', { year: '2-digit', month: '2-digit', day: '2-digit' }),
          [this.$t('datasets.table.fields.reviewDate')]:
            el.last_revision_date ? new Date(el.last_revision_date).toLocaleString('fr-FR', { year: '2-digit', month: '2-digit', day: '2-digit' }) : '',
          // 'Data rattaché': '',
          [this.$t('datasets.table.fields.mainResource')]: el.resource_main ? el.resource_main.display_name : '',
          [this.$t('datasets.table.fields.annexCount')]: el.resource_annexes ? el.resource_annexes.length : '0',
          [this.$t('datasets.table.fields.publish')]: el.publish === null ? true : el.publish,
          [this.$t('datasets.table.fields.access')]: el.default_permission_level
        };
      });
    },

    datasetsPerPage() {
      if (this.$config.datasets && this.$config.datasets.itemsPerPage) {
        return this.$config.datasets.itemsPerPage;
      }
      return 20;
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'DatasetPermissions') {
        vm.RESET_USERGROUPS_PERMISSIONS_FILTERS('usergroup_type_id');
        vm.RESET_USERGROUPS_PERMISSIONS_FILTERS('organisation_type_id');
        vm.RESET_USERGROUPS_PERMISSIONS_FILTERS('level');
      }
    });
  },

  created() {
    // Clean stored form
    this.SET_CURRENT_FORM({
      id: null,
      form: null
    });
    this.SET_TABLE({
      name: 'datasetsListTable',
      table: {
        loading: false,
        currentPage: this.currentTables.find(t => t.name === 'datasetsListTable') ?
          this.currentTables.find(t => t.name === 'datasetsListTable').currentPage :
          1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    // Reset filters
    this.setTableFilters({
      filter: 'publish',
      values: this.publishStatuses.map(el => {
        return {
          ...el,
          state: el.choice === 2 ? true : false
        };
      })
    }, true);

    if (!this.isDatasetsListSearched) {
      this.getData(
        this.currentTables.find(t => t.name === 'datasetsListTable') ?
          this.currentTables.find(t => t.name === 'datasetsListTable').currentPage :
          1
      );
    }

    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isDeleteDatasetWithResourceOpen = false;
    });

    this.initiating = false;
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapMutations('datasets', [
      'RESET_USERGROUPS_PERMISSIONS_FILTERS'
    ]),
    ...mapActions('datasets', [
      'GET_DATASET_DETAIL',
      'GET_DATASETS_LIST',
      'SEARCH_DATASETS_LIST',
      'DUPLICATE_DATASET',
      'DELETE_DATASET',
      'PRESET_DATASETS_FILTERS',
      'EXPORT_DATASET_LIST'
    ]),
    ...mapActions('resources', [
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),

    getData(page) {
      this.loadingDatasetsPage = true;
      this.GET_DATASETS_LIST({ ...this.tableSorted, page: page })
        .then(() => {
          this.loadingDatasetsPage = false;
        })
        .catch(() => {
          this.loadingDatasetsPage = false;
        });
    },

    changeTablePage(e) {
      this.getData(e);
    },

    setTableSorted(e) {
      this.tableSorted = e;
    },

    setTableFilters(e) {
      for (const filter of e.values) {
        if (filter.state) {
          this.PRESET_DATASETS_FILTERS({
            filter: e.filter,
            value: filter.value
          });
        }
      }
      if (!this.initiating) {
        this.getData();
      }
    },

    duplicateDataset(e) {
      this.loadingTable = true;
      this.DUPLICATE_DATASET(e.id)
        .then(() => {
          this.$router.push({
            name: 'AddDataset'
          })
            .then(() => {
              this.loadingTable = false;
            })
            .catch(() => {
              this.loadingTable = false;
            });
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    openDeleteModal(e) {
      this.datasetToDelete = e;
      this.datasetToDelete.display_name = e[this.$t('datasets.table.fields.name')];
      if (!e[this.$t('datasets.table.fields.mainResource')] && !e[this.$t('datasets.table.fields.annexCount')]) {
        this.OPEN_MODAL({
          modal: 'confirmation',
          open: true,
          title: this.$t('modals.deleteDataset.title'),
          content: this.$t('modals.deleteDataset.content.dataset', { dataset: e[this.$t('datasets.table.fields.name')] }),
          trigger: this.deleteDataset
        });
      } else {
        this.datasetWithResourceModalData.name = e[this.$t('datasets.table.fields.name')];
        this.datasetWithResourceModalData.nbResources =
          e[this.$t('datasets.table.fields.annexCount')] + (e[this.$t('datasets.table.fields.mainResource')] ? 1 : 0);
        this.isDeleteDatasetWithResourceOpen = true;
      }
    },

    deleteDataset() {
      this.isDeleteDatasetWithResourceOpen = false;
      this.loadingTable = true;
      this.DELETE_DATASET(this.datasetToDelete)
        .then(() => {
          this.loadingTable = false;
          this.getData();
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    deleteDatasetWithResources() {
      this.isDeleteDatasetWithResourceOpen = false;
      this.loadingTable = true;
      this.GET_DATASET_DETAIL(this.datasetToDelete.id)
        .then(async () => {

          // Delete annexes
          if (this.currentDataset.resource_annexes && this.currentDataset.resource_annexes.length) {
            for (const annex of this.currentDataset.resource_annexes) {
              try {
                await this.DELETE_RESOURCE_FROM_DATASET({
                  resourceToDatasetId: annex.resourcedataset_id,
                  resource: annex
                });
                await this.DELETE_RESOURCE(annex);
              } catch (err) {
                console.error(err);
                this.loadingTable = false;
              }
            }
          }

          // Delete main resource
          if (this.currentDataset.resource_main) {
            try {
              await this.DELETE_RESOURCE_FROM_DATASET({
                resourceToDatasetId: this.currentDataset.resource_main.resourcedataset_id,
                resource: this.currentDataset.resource_main
              });
              await this.DELETE_RESOURCE(this.currentDataset.resource_main);
            } catch (err) {
              console.error(err);
              this.loadingTable = false;
            }
          }

          // Finally delete dataset
          this.DELETE_DATASET(this.currentDataset)
            .then(() => {
              this.loadingTable = false;
              this.getData();
            })
            .catch(() => {
              this.loadingTable = false;
            });
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    goToDatasetPermissions(e) {
      this.$router.push({
        name: 'DatasetPermissions',
        params: {
          id: e,
          datasetName: this.datasetsList.find(el => el.id === e).display_name
        }
      });
    },

    async exportDatasetToODL() {
      this.loadingDatasetsPage = true;
      await this.EXPORT_DATASET_LIST();
      this.loadingDatasetsPage = false;
    }
  }
};
</script>
