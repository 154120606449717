<template>
  <div>
    <div>
      <b-button-group>
        <b-button
          v-for="filter in currentFilters"
          :key="filter.label"
          :pressed.sync="filter.state"
          variant="outline-primary"
          @click="setFilters(filter)"
        >
          {{ filter.label }}
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableFilters',

  props: {
    filterName: {
      type: String,
      default: ''
    },
    choices: {
      type: Array,
      default: () => { return []; }
    }
  },

  data() {
    return {
      currentFilters: []
    };
  },

  watch: {
    choices: {
      deep: true,
      handler(newValue) {
        if (newValue && newValue.length) {
          this.currentFilters = newValue.map(el => {
            return {
              ...el,
              state: false
            };
          });
          this.currentFilters.find(el => el.choice === 2).state = true;
        }
      }
    },
  },

  created() {
    if (this.choices && this.choices.length) {
      this.currentFilters = this.choices.map(el => {
        return {
          ...el,
          state: false
        };
      });
      this.currentFilters.find(el => el.choice === 2).state = true;
      this.$emit('filter', {
        values: this.currentFilters,
        filter: this.filterName
      });
    }
  },

  methods: {
    setFilters(e) {
      this.currentFilters.forEach(filter => {
        if (filter.label !== e.label) {
          filter.state = false;
        } else {
          filter.state = true;
        }
        return filter;
      });
      this.$emit('filter', {
        values: this.currentFilters,
        filter: this.filterName
      });
    }
  }

};
</script>

<style lang="less" scoped>

.btn-outline-primary {
  box-shadow: none !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>
